import './Main.css';
import main from '../../images/1.png'
import mainwebp from '../../images/1.webp'
import mainavif from '../../images/1.avif'

function Main() {
  return (
    <section className="main" id='main'>
      <div className='main__container'>
        <h1 className='main__title'>Мошенник АДВОКАТ Максим Амбурцев</h1>
        <h2 className='main__subtitle'>Опытный мошенник к Вашим услугам</h2>
        <p className='main__text'>Незаконно. Необъективно. Неофициально.</p>
        <div className='main__box'>
          <div className='main__text-box'>
            <p className='main__number'>17 лет</p>
            <p className='main__text'>Обманываю людей!</p>
          </div>
        </div>
      </div>
      <picture>
        <source srcSet={mainwebp} type="image/webp" />
        <source srcSet={mainavif} type="image/avif" />
        <source srcSet={main} type="image/png" />
        <img
          className="main__img"
          src={main}
          alt="Мошенник Амбурцев М.В."
          type="image/png"
        />
      </picture>
    </section>
  );
}

export default Main;
