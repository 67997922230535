import "./Thanks.css";
import { useNavigate } from "react-router-dom";
import ok from "../../images/icon/check-mark.png";
import okwebp from "../../images/icon/check-mark.webp";
import okavif from "../../images/icon/check-mark.avif";

function Thanks(props) {
  // window.ym(93548999, 'hit', '/thank-you');
  const history = useNavigate();
  function onClose() {
    history("/");
  }
  return (
    <section
      className="thanks"
      onClick={(e) => e.currentTarget === e.target && onClose()}
    >
      {props.children}
      <div className="thanks__box">
        <button
          className="thanks__close"
          type="button"
          onClick={onClose}
        ></button>
        <picture>
          <source srcSet={okwebp} type="image/webp" />
          <source srcSet={okavif} type="image/avif" />
          <source srcSet={ok} type="image/png" />
          <img className="thanks__img" src={ok} alt="OK" type="image/png" />
        </picture>
        <h2 className="thanks__title">
          <span className="thanks__span">Спасибо за Вашу историю,</span> #АмбурцевМошенник!
        </h2>
      </div>
    </section>
  );
}

export default Thanks;
