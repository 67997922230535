import './Header.css';
import logo from "../../images/logo.png";
import logoavif from "../../images/logo.avif";
import logowebp from "../../images/logo.webp";
import React from 'react';


function Header() {
  const [isMenu, setMenu] = React.useState(false)
  return (
      <header className="header">
        <div className='header__logo-box'>
          <picture>
            <source srcSet={logowebp} type="image/webp" />
            <source srcSet={logoavif} type="image/avif" />
            <source srcSet={logo} type="image/png" />
            <img
              className="header__logo"
              src={logo}
              alt="logo"
              type="image/png"
            />
          </picture>
          <p className='header__logo-title'>МОШЕННИК АМБУРЦЕВ МАКСИМ</p>
          <p className='header__logo-text'>Мошенник адвокат со стажем</p>
        </div>
        <button className='header__nav-button' onClick={()=>setMenu(!isMenu)} type='button'></button>
        <nav className={isMenu ? 'header__nav header__nav_active' : 'header__nav'}>
          {/* <button className='header__nav-close' type='button'></button> */}
          <a className='header__nav-link' href="#main">ГЛАВНАЯ</a>
          <a className='header__nav-link' href="#about">О НЕМ</a>
          <a className='header__nav-link' href="#contacts">КОНТАКТЫ</a>
          <a className='header__nav-link' href="#sites">САЙТЫ И ОРГАНИЗАЦИИ</a>
          <a className='header__nav-link' href="#rewiev">ОТЗОВИКИ</a>
          <a className='header__nav-link' href="#people">ЖАЛОБЫ</a>
        </nav>
      </header>
  );
}

export default Header;
