import './Rewiev.css';
import otzovik from "../../images/otzovik.png"
import otzovikwebp from "../../images/otzovik.webp"
import otzovikavif from "../../images/otzovik.avif"
import zoon from "../../images/zoon.png"
import zoonwebp from "../../images/zoon.webp"
import zoonavif from "../../images/zoon.avif"
import google from "../../images/googlemap.png"
import googleavif from "../../images/googlemap.avif"
import googlewebp from "../../images/googlemap.webp"
import flamp from "../../images/flamp.png"
import flampavif from "../../images/flamp.avif"
import flampwebp from "../../images/flamp.webp"
import advacotoved from "../../images/advved.png"
import advacotovedavif from "../../images/advved.avif"
import advacotovedwebp from "../../images/advved.webp"
function Rewiev() {
  return (
    <section className="rewiev" id='rewiev'>
      <h2 className='section__title'>ОТЗОВИКИ</h2>
      <div className='rewiev__box'>
        <a className='review__link' href='https://otzovik.com/reviews/advokatskiy_kabinet_amburceva_m_v_russia_novosibirsk/'>
          <picture>
            <source srcSet={otzovikwebp} type="image/webp" />
            <source srcSet={otzovikavif} type="image/avif" />
            <source srcSet={otzovik} type="image/png" />
            <img
              className="rewiev__img"
              src={otzovik}
              alt="Отзовик"
              type="image/png"
            />
          </picture>
        </a>
        <a className='review__link' href='https://nsk.zoon.ru/law/advokatskoj_kabinet_amburtseva_mv/'>
          <picture>
            <source srcSet={zoonwebp} type="image/webp" />
            <source srcSet={zoonavif} type="image/avif" />
            <source srcSet={zoon} type="image/png" />
            <img
              className="rewiev__img"
              src={zoon}
              alt="Zoon"
              type="image/png"
            />
          </picture>
        </a>
        <a className='review__link' href='https://www.google.com/search?q=%D0%90%D0%BC%D0%B1%D1%83%D1%80%D1%86%D0%B5%D0%B2+%D0%9C%D0%B0%D0%BA%D1%81%D0%B8%D0%BC+%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B&oq=%D0%90%D0%BC%D0%B1%D1%83%D1%80%D1%86%D0%B5%D0%B2+%D0%9C%D0%B0%D0%BA%D1%81%D0%B8%D0%BC+%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B&aqs=chrome..69i57.17728j0j7&client=ms-android-samsung-ss&sourceid=chrome-mobile&ie=UTF-8#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&trex=m_t:lcl_akp,rc_ludocids:11353100621166582991,rc_q:%25D0%2590%25D0%25BC%25D0%25B1%25D1%2583%25D1%2580%25D1%2586%25D0%25B5%25D0%25B2%2520%25D0%259C%25D0%25B0%25D0%25BA%25D1%2581%25D0%25B8%25D0%25BC%2520%25D0%2592%25D0%25BB%25D0%25B0%25D0%25B4%25D0%25B8%25D0%25BC%25D0%25B8%25D1%2580%25D0%25BE%25D0%25B2%25D0%25B8%25D1%2587%2520%25D0%25BE%25D1%2582%25D0%25B7%25D1%258B%25D0%25B2%25D1%258B,ru_gwp:0%252C7,ru_q:%25D0%2590%25D0%25BC%25D0%25B1%25D1%2583%25D1%2580%25D1%2586%25D0%25B5%25D0%25B2%2520%25D0%259C%25D0%25B0%25D0%25BA%25D1%2581%25D0%25B8%25D0%25BC%2520%25D0%2592%25D0%25BB%25D0%25B0%25D0%25B4%25D0%25B8%25D0%25BC%25D0%25B8%25D1%2580%25D0%25BE%25D0%25B2%25D0%25B8%25D1%2587%2520%25D0%25BE%25D1%2582%25D0%25B7%25D1%258B%25D0%25B2%25D1%258B,trex_id:W0HGN'>
          <picture>
            <source srcSet={googlewebp} type="image/webp" />
            <source srcSet={googleavif} type="image/avif" />
            <source srcSet={google} type="image/png" />
            <img
              className="rewiev__img"
              src={google}
              alt="Google"
              type="image/png"
            />
          </picture>
        </a>
        <a className='review__link' href='https://novosibirsk.flamp.ru/firm/advokatskijj_kabinet_amburceva_m_v-70000001051407045/otzyv-6890440'>
          <picture>
            <source srcSet={flampwebp} type="image/webp" />
            <source srcSet={flampavif} type="image/avif" />
            <source srcSet={flamp} type="image/png" />
            <img
              className="rewiev__img"
              src={flamp}
              alt="Flamp"
              type="image/png"
            />
          </picture>
        </a>
        <a className='review__link' href='https://advokatoved.ru/novosibirsk/amburtsev-maksim-54-1648/ '>
          <picture>
            <source srcSet={advacotovedwebp} type="image/webp" />
            <source srcSet={advacotovedavif} type="image/avif" />
            <source srcSet={advacotoved} type="image/png" />
            <img
              className="rewiev__img"
              src={advacotoved}
              alt="Адвокатовед"
              type="image/png"
            />
          </picture>
        </a>
      </div>
      <h3 className='rewiev__title'>Отзывы людей в ВК:</h3>
      <div className='rewiev__box'>
        <div className='rewiev__article-box'>
          <a className='rewiev__button' href='https://vk.com/id744481370?w=wall744481370_9%2Fall'>Читать</a>
        </div>
        <div className='rewiev__article-box'>
          <a className='rewiev__button' href='https://vk.com/id753801977?w=wall753801977_8%2Fall'>Читать</a>
        </div>
        <div className='rewiev__article-box'>
          <a className='rewiev__button' href='https://vk.com/id755081475?w=wall755081475_7%2Fall'>Читать</a>
        </div>
        <div className='rewiev__article-box'>
          <a className='rewiev__button' href='https://vk.com/id754926874?w=wall754926874_8%2Fall'>Читать</a>
        </div>
        <div className='rewiev__article-box'>
          <a className='rewiev__button' href='https://vk.com/id744028594?w=wall744028594_8%2Fall'>Читать</a>
        </div>
      </div>
    </section>
  );
}

export default Rewiev;
