
import Thanks from '../Thanks/Thanks';
import { Helmet } from "react-helmet";
import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import Home from '../Home/Home';

function App() {
  const [thanks, setThanks] = React.useState(false);
  return (
      <Routes>
        <Route
        path='/'
        element={
        <Home
        setThanks={setThanks}
        >
          <Helmet>
                <meta
                  name="description"
                  content="Мошенник Амбурцев Максим Владимирович"
                />
                <title>Мошенник Амбурцев Максим Владимирович</title>
                <meta name="robots" content="index,follow" />
                <meta name="keywords" content="" />
                <meta
                  property="og:title"
                  content='Мошенник Амбурцев Максим Владимирович'
                />
                <meta
                  property="og:description"
                  content="Мошенник Амбурцев Максим Владимирович"
                />
              </Helmet>
        </Home>}
        >
          <Route
          path='/thank-you'
          element={
            thanks ? (
              <Thanks>
                <Helmet>
                  <meta
                    name="description"
                    content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                  />
                  <title>Спасибо</title>
                  <meta name="robots" content="none" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Мошенник Амбурцев Максим Владимирович" />
                  <meta
                    property="og:description"
                    content="Спасибо за Вашу историю о мошеннике Амбурцеве Максиме Владимировиче!"
                  />
                </Helmet>
              </Thanks>
            ) : (
              <Navigate to="/" />
            )
          }
          ></Route>
        </Route>
      </Routes>
  );
}

export default App;
