import './Contacts.css';

function Contacts() {
  return (
    <section className="contacts">
    <h2 className='section__title' id='contacts'>КОНТАКТЫ И РЕКВИЗИТЫ</h2>
    <div className='contacts__box'>
      <p className='contacts__title'>Адреса:</p>
      <p className='contacts__subtitle'>г. Москва, ул. Нижняя Красносельская, д. 35, стр. 50​</p>
      <p className='contacts__subtitle'>​г. Новосибирск, ул. Орджоникидзе, 37/1​207 офис; 2 этаж</p>
    </div>
    <div className='contacts__box'>
      <p className='contacts__title'>Телефоны:</p>
      <a className='contacts__subtitle' href="tel:+79163645754">8-916-364-57-54</a>
      <a className='contacts__subtitle' href="tel:+79513737173">8-951-373-71-73</a>
      <a className='contacts__subtitle' href="tel:+73833813031">8-383-381-30-31</a>
      <a className='contacts__subtitle' href="tel:+73832185657">8-383-218-56-57</a>
      <a className='contacts__subtitle' href="tel:+79529152232">8-952-915-22-32</a>
      <a className='contacts__subtitle' href="tel:+79831318381">8-983-131-83-81</a>
    </div>
    <div className='contacts__box'>
      <p className='contacts__title'>Прочие:</p>
      <a className='contacts__subtitle' target='_blank' rel="noreferrer" href="https://vk.com/m.amburtsev">vk.com/m.amburtsev</a>
      <a className='contacts__subtitle' href="mailto:3813031@gmail.com">3813031@gmail.com</a>
    </div>
    <h2 className='section__title' id='sites'>САЙТЫ И ОРГАНИЗАЦИИ</h2>
    <div className='contacts__box'>
      <p className='contacts__title'>Его сайт:</p>
      <a className='contacts__subtitle' target='_blank' rel="noreferrer" href="https://www.amburtsev.ru ">www.amburtsev.ru </a>
    </div>
    <div className='contacts__box'>
      <p className='contacts__title'>Коллегия адвокатов: </p>
      <a className='contacts__subtitle' target='_blank' rel="noreferrer" href="https://novikov-advokat.ru/our-advokats/amburtsev_maksim/">novikov-advokat.ru</a>
    </div>
    <div className='contacts__box'>
      <p className='contacts__title'>На этом сайте он как сотрудник: </p>
      <a className='contacts__subtitle' target='_blank' rel="noreferrer" href="https://центр-сотрудничество.рф/amburtsev-maksim-vladimirovich">центр-сотрудничество.рф</a>
    </div>
    </section>
  );
}

export default Contacts;
