import './Form.css';
import React from "react";

function Form(props) {
  const [spanStory, setSpanStory] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [spanName, setSpanName] = React.useState(false);
  const [spanId, setSpanId] = React.useState(false);
const regex = /^[0-9]+$/;
  function onClear() {
    props.setId("");
    props.setName("");
    props.setStory("");
    setSpanId(false);
    setSpanName(false);
    setSpanStory(false);
    setDisabled(true);
  }
  return (
    <section className="form-box">
      <form className='form'>
        <h3 className='form__title'>Если у Вас есть история про мошенника адвоката Амбурцева Максима Владимировича, то обязательно расскажите о ней тут!</h3>
        <div className='form__input-box'>
        <div className="input__label">
          <span
            className={
              spanName ? "input__span input__span_active" : "input__span"
            }
          >
            Имя
          </span>
          <input
            className={
              spanName
                ? "form__input form__input_active"
                : "form__input"
            }
            name="name"
            type="text"
            placeholder="Как вас зовут?"
            autoComplete="off"
            minLength="2"
            maxLength="30"
            value={props.name}
            onFocus={() => setSpanName(true)}
            onBlur={(e) =>
              e.target.value === "" ? setSpanName(false) : setSpanName(true)
            }
            onChange={(e) => {
              props.setName(e.target.value);
              e.target.value.length !== "" && props.id.length === 16 && props.story !== ""
                ? setDisabled(false)
                : setDisabled(true);
            }}
            required
          />
        </div>
        <div className="input__label">
          <span
            className={
              spanId ? "input__span input__span_active" : "input__span"
            }
          >
            Ваш ВК ID
          </span>
          <input
            className={
              spanId
                ? "form__input form__input_active"
                : "form__input"
            }
            name="number"
            type="number"
            placeholder="Ваш ВК ID"
            autoComplete="off"
            onFocus={() => setSpanId(true)}
            onBlur={(e) =>
              e.target.value === "" ? setSpanId(false) : setSpanId(true)
            }
            value={props.id}
            minLength="16"
            maxLength="16"
            onChange={(e) => {
              props.setId(e.target.value);
              props.name !== "" &&
              e.target.value !== "" &&
              regex.test(e.target.value) && props.story !== ""
                ? setDisabled(false)
                : setDisabled(true);
            }}
            required
          />
        </div>
        <div className="input__label">
            <span
              className={
                spanStory ? "input__span input__span_active" : "input__span"
              }
            >
              Ваша история
            </span>
            <input
              className={
                spanStory
                  ? "form__input form__input_story form__input_active"
                  : "form__input form__input_story"
              }
              name="story"
              type="text"
              placeholder="Ваша история"
              autoComplete="off"
              minLength="10"
              maxLength="3000"
              value={props.story}
              onFocus={() => setSpanStory(true)}
              onBlur={(e) =>
                e.target.value === ""
                  ? setSpanStory(false)
                  : setSpanStory(true)
              }
              onChange={(e) => {
                props.setStory(e.target.value);
                e.target.value.length !== "" && props.name !== "" && props.id !== ""
                  ? setDisabled(false)
                  : setDisabled(true);
              }}
              required
            />
          </div>
        <div className="form__button-box">
          <button
            className={
              isDisabled
                ? "form__button form__button_min form__button_disabled"
                : "form__button form__button_min"
            }
            disabled={isDisabled}
            type="submit"
            value="Оставить заявку"
            onClick={(e) => {
              e.preventDefault();
              props.postStory(e);
              onClear();
            }}
          >
            ОТПРАВИТЬ
          </button>
          <p className="form__policy form__policy_min">
            Нажимая на кнопку, вы принимаете{" "}
            <span
              className="form__policy_span form__policy_span_min"
              onClick={() => props.setOpenPopupPolicy(true)}
            >
              условия обработки данных
            </span>
          </p>
        </div>
        </div>
      </form>
    </section>
  );
}

export default Form;
