import './People.css';
import vk from '../../images/icon/vk.svg'
import React from "react";
function People(props) {
  const [isKey, setKey] = React.useState(0);
  const [isMore, setMore] = React.useState(false);
  const [isMoreStory, setMoreStory] = React.useState(2);
  const scollToRef = React.useRef()
  function moreStory() {
    if (isMoreStory >= props.arrStory.length) {
      setMoreStory(2);
      scollToRef.current.scrollIntoView();
    } else {
      setMoreStory(isMoreStory + 2)
    }
  }
  return (
    <section className="people" id='people' ref={scollToRef}>
      <h2 className='section__title'>ЖАЛОБЫ</h2>
      <div className='people__main-box'>
          {
            props.arrStory.length !== 0 
            ? props.arrStory.slice(0,isMoreStory).map((a)=>(
        <div className='people__box' key={`${props.arrStory.indexOf(a)}story`}>
          <a className='people__link' href={`https://vk.com/id${a.id}`}>
            <img className='people__vk' src={vk} alt='VK'/>
            <p className='people__name'>{a.name}</p>
          </a>
          <p className={isMore && props.arrStory.indexOf(a) === Number(isKey) ? 'people__story people__story_more' : 'people__story'}>{a.story}</p>
          <button type='button' id={props.arrStory.indexOf(a)} 
          onClick={(e)=> {
              if (isMore && isKey !== props.arrStory.indexOf(a)) {
                setMore(true);
                setKey(props.arrStory.indexOf(a));
              } else {
                setMore(!isMore);
                setKey(props.arrStory.indexOf(a));
              }}
            } 
            className='people__more-story'>{isMore && props.arrStory.indexOf(a) === Number(isKey) ? 'Скрыть' : 'Больше'}</button>
        </div>
            ))
            : null
          }
      </div>
      {
      props.arrStory.length > 2
      ? <button className='people__more-button' type="button" onClick={()=>{moreStory()}}>{isMoreStory >= props.arrStory.length ? 'Скрыть истории' : 'Больше историй'}</button>
      : null
      }
      
    </section>
  );
}

export default People;
