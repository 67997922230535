import About from '../About/About';
import Contacts from '../Contacts/Contacts';
import Form from '../Form/Form';
import Header from '../Header/Header';
import Main from '../Main/Main';
import People from '../People/People';
import Rewiev from '../Rewiev/Rewiev';
import axios from "axios";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

function Home(props) {
    const [id, setId] = React.useState("");
    const [name, setName] = React.useState("");
    const [story, setStory] = React.useState("");
    const [arrStory, setArrStory] = React.useState([{}]);
    const history = useNavigate();
    React.useEffect(() => {
      axios({
        method: "GET",
        url: "./getStory.php",
        headers: {
          "Access-Control-Allow-Headers": "content-type",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setArrStory(res.data)
        });
    }, []);
        
    function postStory(e){
      e.preventDefault()
      let formData = new FormData();
      formData.append("story", story);
      formData.append("name", name);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "./postStory.php",
        headers: {
          "Content-Type": "multipart/form-data",
        },
          data: formData,
      })
        .then((res) => {
          if (res.data === "ok") {
            props.setThanks(true);
            history("/thank-you");
          }
        })
        .then(()=>{
          axios({
            method: "GET",
            url: "./getStory.php",
            headers: {
              "Access-Control-Allow-Headers": "content-type",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }).then((res) => {
            setArrStory(res.data)
            });
        })
        .catch(function (res) {
          console.log(res);
        });
    }
    return (
      <div className="App">
        {props.children}
        <Outlet/>
          <Header/>
        <Main/>
        <About/>
        <Contacts/>
        <Rewiev/>
        <People
        arrStory={arrStory}
        />
        <Form
        id={id}
        setId={setId}
        name={name}
        setName={setName}
        story={story}
        setStory={setStory}
        postStory={postStory}
        />
      </div>
    );
  }
  
  export default Home;
  