import './About.css';
import main from '../../images/1.png'
import mainwebp from '../../images/1.webp'
import mainavif from '../../images/1.avif'
import videoimg1 from '../../images/getcontact/1.png'
import videoimg1webp from '../../images/getcontact/1.webp'
import videoimg1avif from '../../images/getcontact/1.avif'
import videoimg2 from '../../images/getcontact/2.png'
import videoimg2webp from '../../images/getcontact/2.webp'
import videoimg2avif from '../../images/getcontact/2.avif'
import videoimg3 from '../../images/getcontact/3.png'
import videoimg3webp from '../../images/getcontact/3.webp'
import videoimg3avif from '../../images/getcontact/3.avif'
import videoimg4 from '../../images/getcontact/4.png'
import videoimg4webp from '../../images/getcontact/4.webp'
import videoimg4avif from '../../images/getcontact/4.avif'
import videoimg5 from '../../images/getcontact/5.png'
import videoimg5webp from '../../images/getcontact/5.webp'
import videoimg5avif from '../../images/getcontact/5.avif'
import React from 'react';

function About() {
  const [isVideo, setVideo] = React.useState(1)
  const videoImages = [
    {
      key: 1,
      src: videoimg1,
      srcwebp: videoimg1webp,
      srcavif: videoimg1avif
    },
    {
      key: 2,
      src: videoimg2,
      srcwebp: videoimg2webp,
      srcavif: videoimg2avif
    },
    {
      key: 3,
      src: videoimg3,
      srcwebp: videoimg3webp,
      srcavif: videoimg3avif
    },
    {
      key: 4,
      src: videoimg4,
      srcwebp: videoimg4webp,
      srcavif: videoimg4avif
    },
    {
      key: 5,
      src: videoimg5,
      srcwebp: videoimg5webp,
      srcavif: videoimg5avif
    }
  ]
  return (
    <section className="about" id="about">
      <h2 className='section__title'>О НЕМ</h2>
      <div className='about__container'>
        <picture>
          <source srcSet={mainwebp} type="image/webp" />
          <source srcSet={mainavif} type="image/avif" />
          <source srcSet={main} type="image/png" />
          <img
            className="about__img"
            src={main}
            alt="Мошенник Амбурцев М.В."
            type="image/png"
          />
        </picture>
        <div className='about__text-box'>
          <h3 className='about__subtitle'>Амбурцев Максим Владимирович - адвокат, мошенник</h3>
          <p className='about__text'>Он - Максим Владимирович Амбурцев, профессиональный мошенник адвокат Адвокатской палаты города Москвы!</p>
          <p className='about__text'>Уже более 17 лет он профессионально занимаюсь мошенническими схемами в сфере юриспруденции и представляет свои финансовые интересы наплевав на доверие Клиентов.</p>
          <p className='about__text'>Что есть для него Адвокатская тайна? Простые слова, ведь все, что ему рассказывают Доверители, разносится за пределы его кабинета. Он обсуждает дела клиентов со всеми, наплевав на закон.</p>
        </div>
      </div>
      <h3 className='about__title'>Вот так он записан у людей в телефонах</h3>
      <div className='about__gallery'>
      <button className='video__arrow video__arrow_left' onClick={()=> isVideo <= 1 ? setVideo(videoImages.length) : setVideo(isVideo - 1)}></button>
      {
        videoImages.map((i)=>(
          <picture>
            <source srcSet={i.srcwebp} type="image/webp" />
            <source srcSet={i.srcavif} type="image/avif" />
            <source srcSet={i.src} type="image/png" />
            <img
              className={isVideo === i.key ? "about__video-img about__video-img_active" : "about__video-img"}
              key={`${i.key}video`}
              src={i.src}
              alt="Мошенник Амбурцев М.В."
              type="image/png"
            />
          </picture>
        ))
      }
      <button className='video__arrow video__arrow_rigth' onClick={()=> isVideo >= videoImages.length ? setVideo(1) : setVideo(isVideo + 1)}></button>
      </div>
    </section>
  );
}

export default About;
